import { Layout, SEO } from 'components';
import * as React from 'react';

interface IPrivacyPolicyProps {
}

const PrivacyPolicy: React.FunctionComponent<IPrivacyPolicyProps> = () => {
  return (

    <Layout>
      <SEO
        title='Privacy Policy'
        description='Do you want to know the benefits of using a goals-based pyramid performance review for your organisation? Check out our article.' />
      <div className="privacy-policy Page_Background d-flex overflow-hidden position-relative">
        <div
          className="sign-up-journey flex-column d-flex flex-grow-1 overflow-auto py-10 px-20"
        >
          <div style={{ maxWidth: '980px', margin: '0 auto' }} className='w-100'>
            <div style={{ borderBottom: '1px solid silver' }} className='py-0 px-8 flex-wrap position-relative'>
              <div className="justify-content-start order-0 d-flex align-items-center">
                <h3>Privacy Policy</h3>
              </div>
              <div className="md-toolbar-section-end"></div>
            </div>
            <div style={{
              maxWidth: '750px',
              margin: '64px auto 0 auto',
              boxShadow: '0 2px 24px -6px rgb(0 0 0 / 24%)',
              borderRadius: '0.5rem'
            }} className='bg-white px-8 py-12'>
              <div className='overflow-hidden'>
                <div style={{ textAlign: 'center' }} className='mb-10'>
                  <div>
                    <strong>TEAMBLE TECHNOLOGY INC.</strong>
                  </div>
                  <div>
                    <strong>PRIVACY POLICY</strong>
                  </div>
                  <div>
                    <strong>Last Updated: March 18, 2024</strong>
                  </div>
                </div>
                <p>
                  This privacy policy (&ldquo;
                  <strong>Policy</strong>&rdquo;)
                  describes how Teamble Technology Inc. and its related companies
                  (jointly referred to as &ldquo;
                  <strong>Teamble</strong>&rdquo;,
                  &ldquo;
                  <strong>we</strong>&rdquo;,
                  &ldquo;
                  <strong>us</strong>&rdquo; or
                  &ldquo;
                  <strong>ours</strong>&rdquo;) collect, use and share your
                  personal information.
                </p>
                <p>
                  This Policy covers the information we collect about you when you
                  use any of our websites (including
                  <a
                    className='text-text-decoration-none'
                    href="https://teamble.com"
                  > teamble.com</a>, the
                  &ldquo;
                  <strong>Site</strong>&rdquo;), products and services, or
                  otherwise interact with us, unless a different policy is
                  referenced. We refer to all of our products, services and websites
                  (including the Site) as &ldquo;
                  <strong>Services</strong>&rdquo; in
                  this Policy.
                </p>
                <p>
                  This Policy does not apply to websites that refer to different
                  privacy statements.
                </p>
                <p>
                  Recent updates to this Policy reflect changes in data protection
                  law. In addition, we have worked to make the Policy clearer and
                  more understandable. We may further revise this Policy at any
                  time. If we make any changes, we will post any such changes on
                  this page and revise the &lsquo;Last Updated&rsquo; date above.
                </p>
                <h2>
                  <strong>TABLE OF CONTENTS:</strong>
                </h2>
                <ul>
                  <li>Who We Are And How To Contact Us</li>
                  <li>Lodging A Complaint</li>
                  <li>What Personal Information We Collect And How</li>
                  <li>How We Use Your Personal Information</li>
                  <li>How We Share Your Personal Information</li>
                  <li>How We Store And Secure Your Personal Information</li>
                  <li>How Long We Keep Your Personal Information</li>
                  <li>How To Access And Control Your Personal Information</li>
                  <li>Other Important Privacy Information</li>
                </ul>
                <p className="underline text-decoration-underline">
                  <strong>WHO WE ARE AND HOW TO CONTACT US</strong>
                </p>
                <p>
                  Teamble Technology Inc. is the controller of your personal
                  information for the purposes of this Policy.
                </p>
                <p>You can contact us using the following details:</p>
                <div className="my-8">
                  <div className="underline text-decoration-underline">Address</div>
                  <div>448 W 37th Street Suite 8C</div>
                  <div>New York, NY 10018</div>
                  <div>United States of America</div>
                </div>
                <div className="my-8">
                  <div className="underline text-decoration-underline">Email</div>
                  <div>
                    <a
                      className='text-text-decoration-none'
                      href="mailto:privacy@teamble.com"> privacy@teamble.com</a>
                  </div>
                </div>
                <div className="my-8">
                  <div className="underline text-decoration-underline">Telephone</div>
                  <div>+1 (888) 562-3981</div>
                </div>
                <p>
                  You may also contact our Data Protection Officer (DPO) directly at
                  <a
                    className='text-text-decoration-none'
                    href="mailto:DPO@teamble.com"> DPO@teamble.com </a>.
                </p>
                <p className="underline text-decoration-underline">
                  <strong>LODGING A COMPLAINT</strong>
                </p>
                <p>
                  If you would like to make a complaint regarding this Policy or our
                  practices in relation to your personal information, please contact
                  us at:
                  <a
                    className='text-text-decoration-none'
                    href="mailto:privacy@teamble.com"> privacy@teamble.com</a>.
                </p>
                <p>We will reply to your complaint as soon as we can.</p>
                <p>
                  If you feel that your complaint has not been adequately resolved,
                  please note that data protection law gives you the right to
                  contact your local data protection supervisory authority.
                </p>
                <p className="underline text-decoration-underline">
                  <strong>WHAT PERSONAL INFORMATION WE COLLECT AND HOW</strong>
                </p>
                <p>
                  We collect information about you in a number of ways described
                  below.
                </p>
                <p>
                  <strong>Information you provide to us:</strong>
                </p>
                <ul>
                  <li>
                    When you sign up for our Services, set preferences, modify your
                    profile or make purchases through the Services, we will collect
                    your
                    &nbsp;<span className="underline text-decoration-underline">name</span>,
                    &nbsp;<span className="underline text-decoration-underline">email address</span>,
                    &nbsp;<span className="underline text-decoration-underline">phone number</span>,
                    &nbsp;<span className="underline text-decoration-underline">fax number</span>,
                    &nbsp;<span className="underline text-decoration-underline">billing address</span>,
                    &nbsp;<span className="underline text-decoration-underline">postal address</span>,
                    &nbsp;<span className="underline text-decoration-underline">username</span>,
                    &nbsp;<span className="underline text-decoration-underline">password</span>,
                    &nbsp;<span className="underline text-decoration-underline">job title</span>,
                    &nbsp;<span className="underline text-decoration-underline">
                      demographic information (such as your gender pronoun and occupation)
                    </span>, as well as
                    &nbsp;<span className="underline text-decoration-underline">any other information</span> you directly give
                    us through the Services.
                  </li>
                </ul>
                <ul>
                  <li>
                    The Services also include our customer support, where you may
                    choose to submit
                    &nbsp;<span className="underline text-decoration-underline">
                      information regarding a problem you are
                      experiencing with a Service
                    </span>. Whether you designate yourself as a
                    technical contact, open a support ticket, speak to one of our
                    representatives directly or otherwise engage with our support
                    team, you will be asked to provide
                    &nbsp;<span className="underline text-decoration-underline">contact information</span>, a
                    summary of the problem you are experiencing, and any other
                    documentation, screenshots or information that would be helpful
                    in resolving the issue.
                  </li>
                </ul>
                <p>
                  (together, &ldquo;
                  <strong>Identity Data</strong>&rdquo;).
                </p>
                <p>
                  <strong>
                    Information submitted by you through your use of the Services:
                  </strong>
                  We collect information that you input into the Services or
                  otherwise provide directly to us. This includes information that
                  you post, send, receive and share as part of the Services, such as
                  feedback, development goals, and participation in &lsquo;pulse
                  check&rsquo; surveys (&ldquo;
                  <strong>Usage Data</strong>&rdquo;).
                </p>
                <p>
                  <strong>Payment information:</strong>We collect certain
                  &nbsp;<span className="underline text-decoration-underline">
                    payment
                    and billing information
                  </span> when you register for certain paid
                  Services. For example, we ask you to designate a billing
                  representative, including
                  &nbsp;<span className="underline text-decoration-underline">name and contact information</span>, upon
                  choosing a paid offering. You might also provide payment
                  information, such as
                  &nbsp;<span className="underline text-decoration-underline">payment card details</span>, which we collect via
                  secure payment processing services (&ldquo;
                  <strong>Payment Data</strong>&rdquo;).
                </p>
                <p>
                  <strong>Information we source from others:</strong> We may receive
                  &nbsp;<span className="underline text-decoration-underline">information about you from other sources</span>. We may combine this
                  information with the information we collected directly from you or
                  through your use of the Services.
                </p>
                <p>
                  <strong>Information collected through hosting platforms: </strong>
                  Many of our Services integrate with other online platforms
                  (such as Slack, Microsoft Teams, and Google collectively referred to as “Platforms”).
                  When adding the relevant Services to the Platforms,
                  we request permissions which allow sharing of information
                  such account and profile information or settings of the relevant Platform (&ldquo;<strong>Platform Data</strong>&rdquo;).
                  Teamble’s use and transfer to any other app of information received from Google APIs
                  will adhere to
                  <a
                    className='text-text-decoration-none'
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  > Google API Services User Data Policy </a>,
                  including the Limited Use requirements. Teamble does not share any user data with third-party tools, including AI models.
                </p>
                <p>
                  <strong>Information collected by automated means:</strong> We will
                  automatically log information about the device you use to access
                  the Services. This may include information about
                  &nbsp;<span className="underline text-decoration-underline">computer operating system type</span>,
                  &nbsp;<span className="underline text-decoration-underline">browser type</span>,
                  &nbsp;<span className="underline text-decoration-underline">browser language</span>,
                  &nbsp;<span className="underline text-decoration-underline">
                    the website you visited before browsing to our Site
                  </span>, &nbsp;<span className="underline text-decoration-underline">pages you viewed</span>,
                  &nbsp;<span className="underline text-decoration-underline">how long you spent on a page</span>,
                  &nbsp;<span className="underline text-decoration-underline">access times</span> and
                  &nbsp;<span className="underline text-decoration-underline">information about your use of and actions on our Site</span>
                  (&ldquo; <strong>Technical Data</strong>&rdquo;).
                </p>
                <p>
                  <strong>Cookies:</strong> We will collect &nbsp;<span className="underline text-decoration-underline">information using
                    &lsquo;cookies&rsquo;</span>. Cookies are small data files stored on your
                  hard drive by a website. We may use both session cookies (which
                  expire once you close your web browser) and persistent cookies
                  (which stay on your computer until you delete them) to provide you
                  with a more personal and interactive experience on our Site. This
                  type of information is collected to make the Site more useful to
                  you and to tailor the experience with us to meet your special
                  interests and needs. Either we or our affiliates and authorized
                  service providers may also use &nbsp;<span className="underline text-decoration-underline">&lsquo;beacons&rsquo;</span>, which are
                  small files embedded onto the pages of our Site. Beacons are used
                  to identify each of our pages in order to be analyzed by our
                  system tools. You have control over which cookies and beacons we
                  are allowed to collect from you through your web browser settings.
                  For more information, please visit
                  <a
                    className='text-text-decoration-none'
                    href="http://www.allaboutcookies.com"
                    target="_blank"
                  > www.allaboutcookies.com</a>
                  (&ldquo;
                  <strong>Cookie Data</strong>&rdquo;).
                </p>
                <p>
                  <strong>Anonymous data:</strong> We will collect &ldquo;
                  <strong>Anonymous Data</strong>&rdquo;, which is data and
                  information that does not permit you to be identified or
                  identifiable, either alone or when combined with any other
                  information available to a third-party. We may create Anonymous
                  Data from the data that we receive about you and other individuals
                  whose data we collect. Anonymous Data might include analytics
                  information and information collected by us using cookies and
                  other third-party software, such as software that monitors how you
                  and other users interact with our site.
                </p>
                <p className="underline text-decoration-underline">
                  <strong>HOW WE USE YOUR PERSONAL INFORMATION</strong>
                </p>
                <p>
                  We will only use your personal information for the purposes for
                  which we collected it as listed below, unless we reasonably
                  consider that we need to use it for another reason and that reason
                  is compatible with the original purpose. If we need to use your
                  personal information for an unrelated purpose, we will update this
                  Policy and we will explain the legal basis which allows us to do
                  so.
                </p>
                <p>
                  In respect of each of the purposes for which we use your personal
                  information, data protection law requires us to ensure that we
                  have a &lsquo;legal basis&rsquo; for that use. Most commonly, we
                  will rely on one of the following legal bases:
                </p>
                <ul>
                  <li>
                    Where we need to perform our contractual obligations to you
                    (&ldquo;
                    <strong>Contractual Necessity</strong>&rdquo;).
                  </li>
                </ul>
                <ul>
                  <li>
                    Where it is necessary for our legitimate interests, and
                    fundamental rights do not override those interests (&ldquo;
                    <strong>Legitimate Interests</strong>&rdquo;).
                  </li>
                </ul>
                <ul>
                  <li>
                    Where we need to comply with a legal or regulatory obligation
                    (&ldquo;
                    <strong>Compliance with Law</strong>&rdquo;).
                  </li>
                </ul>
                <ul>
                  <li>
                    Where we have your specific consent to carry out the processing
                    for the purpose in question (&ldquo;
                    <strong>Consent</strong>&rdquo;).
                  </li>
                </ul>
                <p>
                  Generally, we will not rely on your Consent as a legal basis for
                  using your personal information other than in the context of
                  marketing communications.
                </p>
                <div className='overflow-auto'>
                  <table className='PrivacyPolicy_Table'>
                    <thead>
                      <tr>
                        <th style={{

                        }} >
                          <p>
                            <strong>Purpose</strong>
                          </p>
                        </th>
                        <th>
                          <p>
                            <strong>Categories of personal information</strong>
                          </p>
                        </th>
                        <th>
                          <p>
                            <strong>Legal basis</strong>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong>
                              Create and manage your account to use the Services.
                            </strong>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Identity Data</li>
                            <li>Usage Data</li>
                            <li>Platform Data</li>
                          </ul>
                        </td>
                        <td>
                          <p><strong>Contractual Necessity</strong>.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Process your payments for the Services.</strong>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Identity Data</li>
                            <li>Platform Data</li>
                            <li>Payment Data</li>
                          </ul>
                        </td>
                        <td>
                          <p><strong>Contractual Necessity</strong>.</p>
                          <br />
                          <p><strong>Compliance with Law</strong>.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Prevent fraud and protect our Services.</strong>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Identity Data</li>
                            <li>Platform Data</li>
                            <li>Technical Data</li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <strong>Legitimate Interests</strong> &ndash; we have a
                            legitimate interest in ensuring the ongoing security and
                            proper operation of our Services and associated IT
                            services and networks.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Troubleshooting and customer support.</strong>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Identity Data</li>
                            <li>Usage Data</li>
                            <li>Platform Data</li>
                            <li>Technical Data</li>
                          </ul>
                        </td>
                        <td>
                          <p><strong>Contractual Necessity</strong>.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong
                            >Operate, monitor and improve our Services.</strong
                            >
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Platform Data</li>
                            <li>Technical Data</li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <strong>Legitimate Interests</strong> &ndash; we have a
                            legitimate interest in monitoring and ensuring the proper
                            operation of our Services, and in improving our Services
                            to deliver a better experience to you and our other
                            customers.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Send marketing communications to you.</strong>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>Identity Data</li>
                            <li>Platform Data</li>
                            <li>Cookie Data</li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <strong>Legitimate Interests</strong> &ndash; we have a
                            legitimate interest in providing you with updates on our
                            Services and related offers where you have purchased or
                            shown interest in similar services from us.
                          </p>
                          <p><strong>Consent</strong>.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  Where we need to process your personal information either to
                  comply with law or to perform our contractual obligations, and you
                  fail to provide that information when requested, we may not be
                  able to provide you with all functionalities of the Services.
                </p>
                <p>
                  &nbsp;<span className="underline text-decoration-underline"><strong>HOW WE SHARE YOUR PERSONAL INFORMATION</strong></span>
                </p>
                <p>
                  The md-table below describes who we may share your personal
                  information with and why we share it.
                </p>
                <p>
                  Where we do so, we always ensure that the recipients of your
                  personal information have signed up to robust provisions to ensure
                  the security and integrity of your personal information.
                </p>
                <div className='overflow-auto'>
                  <table className='PrivacyPolicy_Table'>
                    <thead>
                      <tr>
                        <th>
                          <p>
                            <strong>Category of recipients</strong>
                          </p>
                        </th>
                        <th>
                          <p>
                            <strong>Reasons for sharing</strong>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong>Other users of the Services.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            You can create content on the Services which may contain
                            information about you, and grant permission to others to
                            see, share, edit, copy and download that content based on
                            settings you or your administrator (if applicable) select.
                          </p>
                          <br />
                          <p>
                            For example, when you send a direct feedback to a teammate
                            on the Services, we display your profile picture and name
                            next to your feedback so that the recipient with access to
                            the feedback can understand who sent the feedback.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Your employer and/or IT administrator.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            If you register or access the Services using an email
                            address with a domain that is owned by your employer or
                            organization, and such organization wishes to establish an
                            account or site, certain information about you including
                            your name, profile picture, contact information, content
                            and past use of your account may become accessible to that
                            organization&rsquo;s administrator and other Service users
                            sharing the same domain.
                          </p>
                          <br />
                          <p>
                            If you are an administrator for a particular site or group
                            of users within the Services, we may share your contact
                            information with current or past Service users, for the
                            purpose of facilitating Service-related requests.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Our services providers.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            We may share your personal information with our service
                            providers that help us operate our Services by providing a
                            range to services to us, such as:
                          </p>
                          <ul>
                            <li>
                              Cloud computing, data storage, hosting, backup and IT
                              maintenance (such as Amazon Web Services Inc.);
                            </li>
                            <li>
                              Customer success and customer support (such as Zendesk
                              Inc.);
                            </li>
                            <li>Payments processing (such as Stripe Inc.); and</li>
                            <li>
                              Transactional e-mail notifications of our services and
                              communications (such as Twilio Inc.).
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Our professional advisers.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            We may share your personal information with our lawyers,
                            bankers, auditors and insurers where necessary for them to
                            provide professional advice to us.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Data protection authorities.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            In certain circumstances, we may be under an obligation to
                            share your personal information with data protection
                            authorities in some territories, for example to report on
                            our data processing activities.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Other public authorities.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            We may also be under an obligation to share your personal
                            information with other public authorities under certain
                            circumstances to comply with applicable law, for example
                            in connection with our tax reporting.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>In the event of a sale of our business.</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            We may share your personal information when we do a
                            business deal, or negotiate a business deal, involving the
                            sale or transfer of all or a part of our business or
                            assets.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  We are a U.S.-based company. Although we do not have an
                  establishment within the European Economic Area (&ldquo;
                  <strong>Europe</strong>&rdquo;), because we target the Services at
                  individuals who are located in Europe, European data protection
                  laws apply to us.
                </p>
                <p>
                  For this reason, we take appropriate steps to ensure that when we
                  process your personal information, we do so in accordance with
                  European data protection laws.
                </p>
                <p>
                  In addition, where we transfer your personal information to any
                  recipients outlined above, we also take appropriate steps to
                  ensure that we have implemented appropriate safeguards in
                  accordance with European data protection laws to ensure that such
                  protection is maintained and is not undermined as a result of that
                  disclosure. These may include the following:
                </p>
                <ul>
                  <li>
                    <strong>Adequacy decision:</strong> We may transfer your
                    Personal Data to countries that have been deemed to provide an
                    adequate level of protection for Personal Data by the European
                    Commission. For further details, please see
                    <a
                      className='text-text-decoration-none'
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
                    > here</a
                    >.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Model Clauses:</strong> We may use specific contracts
                    approved by the European Commission, which give Personal Data
                    the same protection it has in Europe. For further details,
                    please see
                    <a

                      className='text-text-decoration-none'
                      href="https://ec.europa.eu/info/strategy/justice-and-fundamental-rights/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
                      target="_blank"
                    > here</a>. If you wish to obtain further details of those contractual
                    arrangements, please contact us at
                    <a
                      className='text-text-decoration-none'
                      href="mailto:privacy@teamble.com"> privacy@teamble.com</a>.
                  </li>
                </ul>
                <p className="underline text-decoration-underline">
                  <strong>HOW WE STORE AND SECURE YOUR PERSONAL INFORMATION</strong>
                </p>
                <p>
                  We have put in place appropriate security measures to prevent your
                  personal information from being accidentally lost, used or
                  accessed in an unauthorized way, altered or disclosed.
                </p>
                <p>
                  We limit access to your personal information to those employees
                  and other staff who have a business need to have such access. All
                  such people are subject to a contractual duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any actual or
                  suspected breach of your personal information, all of which our
                  employees are informed and trained on. In the event of any such
                  breach, we have systems in place to work with applicable
                  regulators. In addition, in certain circumstances (e.g., where we
                  are legally required to do so) we may notify you of breaches
                  affecting your personal information.
                </p>
                <p>
                  We use data hosting service providers in the United States to host
                  the information we collect about you.
                </p>
                <p>
                  While we implement safeguards designed to protect your personal
                  information, no security system is impenetrable and due to the
                  inherent nature of the Internet, we cannot guarantee that data,
                  during transmission through the Internet or while stored on our
                  systems or otherwise in our care, is absolutely safe from
                  intrusion by others.
                </p>
                <p>
                  We also urge you to take additional steps on your own to safeguard
                  and maintain the integrity of your information. For example, you
                  should never share your account or login information with other
                  people and be sure to sign off when finished using a shared or
                  public computer.
                </p>
                <p className="underline text-decoration-underline">
                  <strong>HOW LONG WE KEEP YOUR PERSONAL INFORMATION</strong>
                </p>
                <p>
                  We will only retain your personal information for so long as we
                  reasonably need to use it for the purposes set out above, unless a
                  longer retention period is required by law (for example for
                  regulatory purposes).
                </p>
                <p>
                  How long we keep information we collect about you depends on the
                  type of information, as described in further detail below. After
                  such time, we will either delete or anonymize your information or,
                  if this is not possible, we will securely store your information
                  and isolate it from any further use until deletion is possible.
                </p>
                <ul>
                  <li>
                    <strong>Account information:</strong>We retain your account
                    information for as long as your account is active, and for a
                    reasonable period thereafter in case you decide to re-activate
                    the Services.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Information you share on the Services:</strong> If your
                    account is deactivated or disabled, some of your information and
                    the content you have provided will remain in order to allow your
                    team members or other users to make full use of the Services.
                    For example, we continue to display the feedback you sent to
                    your teammates that received them.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Managed accounts:</strong> If the Services are made
                    available to you through an organization (e.g. your employer),
                    we retain your information for as long as required by the
                    administrator of your account.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Marketing information:</strong> If you have elected to
                    receive marketing emails from us, we retain information about
                    your marketing preferences for a reasonable period of time from
                    the date you last expressed interest in our Services, such as
                    when you last opened an email from us or ceased using your
                    Teamble account. We also retain information derived from cookies
                    and other tracking technologies for a reasonable period of time
                    from the date such information was collected.
                  </li>
                </ul>
                <p className="underline text-decoration-underline">
                  <strong>HOW TO ACCESS AND CONTROL YOUR PERSONAL INFORMATION</strong>
                </p>
                <p>
                  Below is a summary of the rights available to you when it comes to
                  your information, how to exercise them and any limitations.
                </p>
                <p>
                  <strong>Your rights:</strong>Under certain circumstances, you may
                  have the right to:
                </p>
                <ul>
                  <li>
                    <strong>Request access to your personal information</strong>.
                    This enables you to receive a copy of the personal information
                    we hold about you, and to check that we are lawfully processing
                    it.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Request the correction of your personal information</strong>. This enables you to have any incomplete or inaccurate
                    information we hold about you corrected.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Request the erasure of your personal information</strong>. This enables you to ask us to delete or remove your personal
                    information where there is no good reason for us to continue
                    processing it. You also have the right to ask us to delete or
                    remove your personal information where you have exercised your
                    right to object to processing (see below).
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Object to the processing of your personal information</strong>. This right exists where we are relying on a &lsquo;Legitimate
                    Interests&rsquo; as the legal basis for our processing, and
                    there is something about your particular situation which makes
                    you want to object to processing on this ground. You also have
                    the right to object where we are processing your personal
                    information for direct marketing purposes.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      Request the restriction of processing of your personal
                      information </strong>. This enables you to ask us to suspend the processing of
                    personal information about you, for example if you want us to
                    establish its accuracy or the reason for processing it.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Request the transfer of your personal information</strong>. We will provide to you, or a third-party you have chosen,
                    your personal information in a structured, commonly used,
                    machine-readable format. Note that this right only applies to
                    automated information which you initially provided consent for
                    us to use or where we used the information to perform a contract
                    with you.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      <strong>Withdraw your consent</strong>. This right only exists
                      where we are relying on your express Consent to process your
                      personal information. If you withdraw your Consent, we may not
                      be able to provide you with access to some functionalities of
                      our Services. We will advise you if this is the case at the
                      time you withdraw your consent.
                    </strong>
                  </li>
                </ul>
                <p>
                  <strong>Exercising your rights:</strong>You can exercise some of
                  the rights detailed above by logging into the Services and using
                  settings available within the Services or your account. Where the
                  Services are administered for you by an administrator, you may
                  need to contact your administrator to assist with your request.
                  For all other requests, you may contact us to request assistance.
                  When you make any request in respect of your personal information,
                  we may need time to investigate and facilitate your request. If
                  there is delay in actioning your request, we will restrict any
                  further use of your information until the request is honored,
                  provided your administrator does not object (where applicable). We
                  may need to request additional details from you to help us confirm
                  your identity and ensure your right to access your personal
                  information. We try to respond to all legitimate requests within
                  one month. Occasionally it may take us longer than a month if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you updated.
                </p>
                <p>
                  <strong>Your rights may be limited in certain cases:</strong> For
                  example, if fulfilling your request would reveal information about
                  another person, or if you ask to delete information which we or
                  your administrator are permitted by law or have compelling
                  legitimate interests to keep. Where you have asked us to share
                  data with third-parties, for example by installing third-party
                  apps, you will need to contact those third-party service providers
                  directly to have your information deleted or otherwise restricted.
                  If you have unresolved concerns, you may have the right to
                  complain to a data protection authority in the country where you
                  live, where you work or where you feel your rights were infringed.
                </p>
                <p>
                  <strong>Deactivating your account:</strong> If you no longer wish
                  to use our Services, you or your administrator may deactivate your
                  account with us in your account settings. If you are unable to
                  deactivate an account through your account settings, please
                  contact us. Please be aware that deactivating your account does
                  not delete your information; your information remains visible to
                  other Service users based on your past participation within the
                  Services. For more information on how to delete your information,
                  see above.
                </p>
                <p>
                  <strong>Opting out of communications:</strong> You may opt out of
                  receiving promotional communications from us by using the
                  unsubscribe link within each email or by contacting us to have
                  your contact information removed from our promotional email list
                  or registration database. Even after you opt out from receiving
                  promotional messages from us, you will continue to receive
                  transactional messages from us regarding our Services. You can opt
                  out of some notification messages in your account settings. You
                  may be able to opt out of receiving personalized advertisements
                  from other companies who are members of the Network Advertising
                  Initiative or who subscribe to the Digital Advertising Alliance's
                  Self-Regulatory Principles for Online Behavioral Advertising. For
                  more information about this practice and to understand your
                  options, please visit:
                  <a
                    className='text-text-decoration-none'
                    href="http://www.aboutads.info" target="_blank"> www.aboutads.info</a>,
                  <a
                    className='text-text-decoration-none'
                    href="http://www.optout.networkadvertising.org" target="_blank"> www.optout.networkadvertising.org</a
                  >
                  and
                  <a
                    className='text-text-decoration-none'
                    href="http://www.youronlinechoices.eu" target="_blank"> www.youronlinechoices.eu</a
                  >.
                </p>
                <p>
                  <strong>Cookie controls:</strong> Some people prefer not to allow
                  cookies, which is why most browsers give you the ability to manage
                  cookies to suit you. In some browsers, you can set up rules to
                  manage cookies on a site-by-site basis, giving you more
                  fine-grained control over your privacy. What this means is that
                  you can disallow cookies from all sites except those that you
                  trust. Please consult the documentation that your browser
                  manufacturer provides to help with cookie management in their
                  product. If you remove or reject our cookies, it could affect how
                  our Site works for you.
                </p>
                <p>
                  <strong>Send "Do Not Track" Signals:</strong> Some browsers have
                  incorporated "Do Not Track" (DNT) features that can send a signal
                  to the websites you visit indicating you do not wish to be
                  tracked. Because there is not yet a common understanding of how to
                  interpret the DNT signal, our Services do not currently respond to
                  browser DNT signals. You can use the range of other tools we
                  provide to control data collection and use, including the ability
                  to opt out of receiving marketing from us as described above.
                </p>
                <p className="underline text-decoration-underline">
                  <strong>OTHER IMPORTANT PRIVACY INFORMATION</strong>
                </p>
                <p>
                  <strong>
                    California Consumer Privacy Act (CCPA) Notice for California
                    residents:
                  </strong>
                </p>
                <p>
                  Effective January 1, 2020, the California Consumer Privacy Act
                  (CCPA) allows California residents to request from a business that
                  collects personal information to give consumers access to and/or
                  deletion of the personal information collected. Terms used in this
                  CCPA Notice but not defined here will have the same meaning as
                  defined under the CCPA.
                </p>
                <ol>
                  <li>
                    Teamble does not sell your personal information to third
                    parties.
                  </li>
                  <li>
                    Teamble discloses personal information for business purposes
                    only.
                  </li>
                  <ul>
                    <li>
                      Business purposes can include such things as: providing our
                      Services, maintaining and servicing accounts, providing
                      customer service, processing or fulfilling orders and
                      transactions, performing analytics and quality control,
                      auditing transactions, researching and testing features and
                      improvements, detecting and preventing fraud and security
                      incidents, debugging or repairing technical errors, and
                      marketing our Services.
                    </li>
                    <li>
                      Teamble may share or allow third parties to collect personal
                      information through our Services for business purposes
                      we&rsquo;ve described in this Policy.
                    </li>
                  </ul>
                  <li>
                    Categories of personal information collected and disclosed. The
                    categories of personal information collected and disclosed to
                    third parties for business purposes by Teamble are listed in the
                    &ldquo;What We Collect&rdquo; section of this Policy.
                  </li>
                  <li>
                    Your rights under CCPA. If you are a California resident using
                    the Services, you have the following rights:
                  </li>
                  <ul>
                    <li>
                      To request the categories of personal information that the
                      business collected about you.
                    </li>
                    <li>
                      To request the categories of personal information that the
                      business disclosed about you for a business purpose.
                    </li>
                    <li>
                      To request deletion of the personal information it has
                      collected from you, subject to certain legal exceptions. For
                      example, when the personal information is necessary to
                      complete a transaction request or to comply with a legal
                      obligation, Teamble may claim an exemption to deletion of your
                      personal information.
                    </li>
                    <li>
                      The right to be protected from discrimination for exercising
                      your CCPA rights. Businesses are prohibited from
                      discriminating against you for exercising your rights under
                      the CCPA, including by: (A) denying you goods or services; (B)
                      charging you different prices or rates for goods or services,
                      including through the use of discounts or other benefits or
                      imposing penalties; (C) providing you with a different level
                      or quality of goods or services; or (D) suggesting that you
                      will receive a different price, rate, level, or quality of
                      goods or services. Nothing prohibits a business from charging
                      a different price or providing a different level or quality of
                      service if the difference is reasonably related to the value
                      provided to the consumer by the consumer&rsquo;s data.
                    </li>
                    <li>
                      Your rights under CCPA may be exempted as permitted under the
                      statute, particularly if you use the Services as an employee
                      or agent under a business account. Teamble expressly reserves
                      all rights to claim legal exemptions permitted under the CCPA.
                    </li>
                  </ul>
                </ol>
                <p>
                  For additional information about this CCPA Notice or to submit a
                  CCPA request, please contact us via mail, e-mail or phone number
                  listed below in the Contact Information section.
                </p>
                <p>
                  <strong>California Online Privacy Protection Act</strong>
                </p>
                <p>
                  In compliance with CalOPPA, we have hereby posted this conspicuous
                  Policy to the public, indicating the personal information being
                  collected and the manner in which it may be disclosed and with
                  whom. Accordingly, our users may visit our Site using anonymous
                  browsing, this Policy linked in our home page, with the link
                  including the word &lsquo;Privacy&rsquo; or similar. We also
                  comply with Policy change notification to our users, and provide
                  mechanisms that allow our users to manage their personal
                  information and personal information.
                </p>
                <p>
                  <strong>
                    European Union General Data Protection Regulation (GDPR)
                  </strong>
                </p>
                <p>
                  As of April 2nd, 2020, Teamble is GDPR compliant in how we handle
                  customer data. We have taken the following steps to ensure
                  we&rsquo;re compliant with the GDPR.
                </p>
                <ul>
                  <li>
                    We have updated this Policy to better explain how we use
                    customer data and how and when we use cookies.
                  </li>
                  <li>
                    We have updated our Terms of Service to support Explicit Consent
                    and Opt-Out.
                  </li>
                  <li>
                    We have appointed a dedicated Data Protection Officer (DPO) to
                    handle a variety of issues in accordance with the GDPR
                    requirements. To communicate with our Data Protection Officer,
                    please email
                    <a
                      className='text-text-decoration-none'
                      href="mailto:DPO@teamble.com"> DPO@teamble.com</a>.
                  </li>
                  <li>
                    We have implemented some compliance measures to make it easy to
                    handle requests such as deletion or update requests of your
                    personal information. To do so, simply e-mail
                    <a
                      className='text-text-decoration-none'
                      href="mailto:privacy@teamble.com"> privacy@teamble.com</a>
                    with your request.
                  </li>
                  <li>
                    We have conducted more awareness and training with our team
                    around data protection and incident response on potential issues
                    like data breaches.
                  </li>
                  <li>
                    We have thoroughly audited all of the services that we use to
                    ensure that they are either GDPR-compliant or don&rsquo;t gain
                    access to personal information. For the third-party services
                    that do handle your personal information, we have been diligent
                    to only allow the minimum amount of critical information
                    required to use the service.
                  </li>
                </ul>
                <p>
                  <strong>Our policy towards children</strong>
                </p>
                <p>
                  The Services are not directed to individuals under 16. We do not
                  knowingly collect personal information from children under 16. If
                  we become aware that a child under 16 has provided us with
                  personal information, we will take steps to delete such
                  information. If you become aware that a child has provided us with
                  personal information, please contact our support services.
                </p>
                <p>
                  <strong>Responsible disclosure</strong>
                </p>
                <p>
                  If you believe you&rsquo;ve discovered a potential vulnerability,
                  please let us know by emailing us at
                  <a
                    className='text-text-decoration-none'
                    href="mailto:privacy@teamble.com"> privacy@teamble.com</a>. We
                  will acknowledge your email within ten business days. Provide us
                  with a reasonable amount of time to resolve the issue before
                  disclosing it to the public or a third-party. We aim to resolve
                  critical issues within one month of disclosure. Make a good faith
                  effort to avoid violating privacy, destroying data, or
                  interrupting or degrading the Teamble service. Please only
                  interact with accounts you own or for which you have explicit
                  permission from the account holder.
                </p>
                <p>While researching, we&rsquo;d like you to refrain from:</p>
                <ul>
                  <li>Distributed Denial of Service (DDoS)</li>
                  <li>Spamming;</li>
                  <li>
                    Social engineering or phishing of Teamble employees or
                    contractors; and
                  </li>
                  <li>
                    Any attacks against Teamble&rsquo;s physical property or data
                    centers.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
